import axios from 'axios'
import Store from '../store'

export const authServiceBaseUrl = 'http://114.130.119.102:8081/'
export const commonServiceBaseUrl = 'http://114.130.119.102:8082/'
export const irriSchemeServiceBaseUrl = 'http://114.130.119.102:8084/'
export const warehouseServiceBaseUrl = 'http://114.130.119.102:8085/'
export const seedFertilizerServiceBaseUrl = 'http://114.130.119.102:8086/'
export const agriMarketingServiceBaseUrl = 'http://114.130.119.102:8083/'
export const incentiveGrantServiceBaseUrl = 'http://114.130.119.102:8087/'
export const trainingElearningServiceBaseUrl = 'http://114.130.119.102:8088/'
export const virtualMuseumServiceBaseUrl = 'http://114.130.119.102:8089/'
export const agriResearchServiceBaseUrl = 'http://114.130.119.102:8091/'
export const licenseRegistrationServiceBaseUrl = 'http://114.130.119.102:8092/'

export const frmsLoginURL = 'http://114.130.119.102:8094/'

// export const authServiceBaseUrl = 'http://134.119.217.113:8081/'
// export const commonServiceBaseUrl = 'http://134.119.217.113:8082/'
// export const agriMarketingServiceBaseUrl = 'http://134.119.217.113:8083/'
// export const irriSchemeServiceBaseUrl = 'http://134.119.217.113:8084/'
// export const warehouseServiceBaseUrl = 'http://134.119.217.113:8085/'
// export const seedFertilizerServiceBaseUrl = 'http://134.119.217.113:8086/'
// export const incentiveGrantServiceBaseUrl = 'http://134.119.217.113:8087/'
// export const trainingElearningServiceBaseUrl = 'http://134.119.217.113:8088/'
// export const agriResearchServiceBaseUrl = 'http://134.119.217.113:8089/'
// export const virtualMuseumServiceBaseUrl = 'http://134.119.217.113:8062/'
// export const licenseRegistrationServiceBaseUrl = 'http://134.119.217.113:8063/'

// Handling server error
const errorHandler = (error) => {
  if (error.response === undefined) {
    return error
  } else if (error.response.status === 401) {
    // Store.dispatch('Auth/updateAuthUser', null)
    // Store.commit('mutateCommonProperties', {
    //   hasDropdownLoaded: false
    // })
    window.vm.$router.push({ path: `/error-page?code=${401}` }).catch()
  } else if (error.response.status === 500) {
    Store.commit('mutateCommonProperties', {
      loading: false,
      listReload: false
    })
    // window.vm.$router.push({ path: `/error-page?code=${500}` }).catch()
  } else {
    // window.vm.$router.push({ path: '/error-page?code=unknown' }).catch()
  }
  return error
}
export default {
  async execute (baseUrl, method, uri, data, params = {}, pdf = false) {
    const accessToken = Store.state.Auth.accessToken
    // const client = axios.create({
    //   baseURL: baseUrl,
    //   json: true
    // })
    let client = []
    if (pdf) {
      client = axios.create({
        baseURL: baseUrl,
        responseType: 'blob'
      })
    } else {
      client = axios.create({
        baseURL: baseUrl,
        json: true
      })
    }

    client.interceptors.response.use(response => response, error => errorHandler(error))
    return client({
      method,
      timeout: 1000000,
      url: uri,
      data,
      params: params,
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        accessUsername: Store.state.Auth.accessUsername,
        accessUserId: Store.state.Auth.accessUserId,
        accessMenuName: window.location.href
      }
    }).then(req => {
      return req.data
    })
  },
  getData (baseUrl, uri, params = {}) {
    // const filters = setFilters()
    return this.execute(baseUrl, 'get', uri, {}, params)
  },
  getPdfData (baseUrl, uri, params = {}) {
    // const filters = setFilters()
    return this.execute(baseUrl, 'get', uri, {}, params, true)
  },
  postData (baseUrl, uri, data) {
    return this.execute(baseUrl, 'post', uri, data)
  },
  putData (baseUrl, uri, data) {
    return this.execute(baseUrl, 'put', uri, data)
  },
  deleteData (baseUrl, uri) {
    return this.execute(baseUrl, 'delete', uri)
  }
}
